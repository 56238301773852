<template>
  <div>
    <a href="https://www.ghwk.de/" target="_blank" rel="noopener">
      <img class="img-fluid mb-4 mt-2" src="@/assets/img/csm_logo-de_480px_mt.png" width="380" alt="Haus am Wannsee">
    </a>
    <div class="container mt-2 text-center">
      <div class="text-center">
        <router-link :to="{ name: 'item'}">
          <button type="button" class="btn btn-lg btn-primary">{{ translate.global.zur_ausstellung[language] }} <i style="padding-left:0.5rem;" class="fa fa-angle-right"></i></button>
        </router-link>
      </div>
      <hr>
      <img class="img-fluid mb-4" src="@/assets/img/photo_composing.jpg" alt="Sammelaufruf">
      <template v-if="$route.name !== 'participate'">
        <p v-html="translate.global.sammelaufruf[language]"></p>
        <router-link
          :to="{ name: 'participate'}">
          <button type="button" class="btn btn-md btn-primary">{{ translate.global.join_in[language] }} <i style="padding-left:0.5rem;" class="fa fa-angle-right"></i></button>
        </router-link>
      </template>
      <template v-if="$route.name == 'participate'">
        <p v-html="translate.global.follow_us_on[language]"></p>
        <a href="https://www.facebook.com/Stumme-Zeugnisse-1939-2520648491285922/" target="_blank" rel="noopener"
          title="Facebook" class="px-2">
          <i class="fa fa-3x fa-facebook-square"></i>
        </a>
        <a href="https://twitter.com/stummezeugnisse" target="_blank" rel="noopener"
          title="Twitter" class="px-2">
          <i class="fa fa-3x fa-twitter-square"></i>
        </a>
        <a href="https://www.instagram.com/sammelaufruf.ghwk" target="_blank" rel="noopener"
          title="Instagram" class="px-2">
          <i class="fa fa-3x fa-instagram"></i>
        </a>
      </template>
      <hr>
      <p>
        <router-link
        :to="{ name: 'exhibition'}"
        v-html="translate.global.all_photos[language]"></router-link> |
        <router-link
        :to="{ name: 'about'}"
        v-html="translate.global.about_this_presentation[language]"></router-link>
      </p>
    </div>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'

export default {
  name: 'DefaultAside',
  computed: {
    ...mapGetters({
      language: 'language',
      translate: 'translate/translate',
    })
  }
}
</script>