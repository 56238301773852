import axios from 'axios'

const state = {
  apiBaseUrl: 'https://ghwk-db.de/api/',
  apiPerPage: 999,
  media: [],
  errors: []
}

export default {
  namespaced: true,
  state,
  getters: {
    media (state) {
      return state.media
    },
    errors (state) {
      return state.errors
    }
  },
  mutations: {
    setMedia (state, payload) {
      state.media = payload
    },
    addError (state, payload) {
      state.errors.push(payload)
    }
  },
  actions: {
    fetchMedia (context) {
      let url = state.apiBaseUrl + 'media'
      // url = 'https://localhost/hdwk-api/media.php'
      axios.get(url, {
        params: {
          'per_page': state.apiPerPage
        }
      })
      .then(response => {
        context.commit('setMedia', response.data)
      })
      .catch(e => {
        context.commit('addError', e)
      })
    }
  }
}