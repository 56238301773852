import Vue from 'vue'
import Router from 'vue-router'
import Home from './views/Home.vue'

Vue.use(Router)

export default new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    {
      path: '/',
      name: 'home',
      component: Home
    },
    // {
    //   path: '/examples',
    //   name: 'examples',
    //   component: () => import('./views/Examples.vue')
    // },
    {
      path: '/exhibition',
      name: 'exhibition',
      component: () => import('./views/Exhibition.vue')
    },
    {
      path: '/about',
      name: 'about',
      component: () => import('./views/About.vue')
    },
    {
      path: '/participate',
      name: 'participate',
      component: () => import('./views/Participate.vue')
    },
    {
      path: '/map',
      name: 'map',
      component: () => import('./views/Map.vue')
    },
    {
      path: '/item/:id?',
      name: 'item',
      component: () => import('./views/Item.vue')
    },
    {
      path: '/person/:id?',
      name: 'person',
      component: () => import('./views/Person.vue')
    },
    {
      path: '/category/:category?',
      name: 'categoryId',
      component: () => import('./views/Category.vue')
    },
    {
      path: '/location/:id?',
      name: 'location',
      component: () => import('./views/Location.vue')
    },
    {
      path: '/date/:date?',
      name: 'date',
      component: () => import('./views/Date.vue')
    },
    {
      path: '/daterange/:earliest?/:latest?',
      name: 'daterange',
      component: () => import('./views/Date.vue')
    }
  ],
  // @see: https://router.vuejs.org/guide/advanced/scroll-behavior.html
  scrollBehavior (to) { // params: to, from, savedPosition
    if (to.name !== 'item') {
      return { x: 0, y: 0 }
    }
  }
})
