import Vue from 'vue'
import Vuex from 'vuex'

import state from './state'
import * as getters from './getters'
import * as mutations from './mutations'
import * as actions from './actions'

import translate from './modules/translate'
import items from './modules/items'
import media from './modules/media'
import locations from './modules/locations'
import persons from './modules/persons'
import wordpress from './modules/wordpress'

Vue.use(Vuex)

export default new Vuex.Store({
  state,
  getters,
  mutations,
  actions,
  modules: {
    translate,
    items,
    media,
    locations,
    persons,
    wordpress
  },
})
