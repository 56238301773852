const state = {
  'global': {
    'exhibit_title': {
      en: 'Digital Photo Exhibition about the 1939 Attack on Poland',
      de: 'Digitale Foto-Ausstellung zum deutschen Überfall auf Polen 1939',
      pl: 'Wystawa online fotografii o niemieckiej napaści na Polskę w 1939 r.'
    },
    'exhibit_subtitle': {
      en: 'From the Collection of Kurt Seeliger',
      de: 'Aus dem Bestand Kurt Seeligers',
      pl: 'Ze zbioru Kurta Seeligera'
    },
    'next_item': {
      en: 'Next item',
      de: 'Nächstes Exponat',
      pl: 'Więcej'
    },
    'previous_item': {
      en: 'Previous item',
      de: 'Vorheriges Exponat',
      pl: 'Z powrotem'
    },
    'exhibition_overview': {
      en: 'overview',
      de: 'Übersicht',
      pl: 'Przegląd'
    },
    'exhibition_item_list_title': {
      en: 'Items in exhibition',
      de: 'Exponate in der Ausstellung',
      pl: 'Eksponaty w prezentacji'
    },
    'archive_item_list_title': {
      en: 'More photos',
      de: 'Weitere Fotos',
      pl: 'Więcej zdjęć'
    },
    'loading_exhibition_data': {
      en: 'Loading exhibition data',
      de: 'Lade Ausstellungsdaten',
      pl: 'Dane są ładowane'
    },
    'bei': {
      en: 'at',
      de: 'bei',
      pl: 'na'
    },
    'Die Seeliger-Fotos': {
      en: 'The Seeliger Photos',
      de: 'Die Seeliger-Fotos',
      pl: 'Fotografie Seeligera'
    },
    'home': {
      en: 'Home',
      de: 'Startseite',
      pl: 'Strona startowa'
    },
    'kontakt': {
      en: 'Contact',
      de: 'Kontakt',
      pl: 'Kontakt'
    },
    'uebersicht': {
      en: 'Übersicht',
      de: 'Übersicht',
      pl: 'Übersicht'
    },
    'select_language': {
      en: 'Select language',
      de: 'Sprache wählen',
      pl: 'Wybierz swój język'
    },
    'sammelaufruf': {
      en: 'Call for Historical Records: “Silent Testimonies 1939 – The German Attack on Poland through Images and Documents”',
      de: 'Sammelaufruf: “Stumme Zeugnisse 1939 – Der deutsche Überfall auf Polen in Bildern und Dokumenten”',
      pl: 'Apel o zbiórkę: “Nieme świadectwa 1939 r. – niemiecka napaść na Polskę na zdjęciach i w dokumentach”'
    },
    'all_photos': {
      en: 'All photos',
      de: 'Alle Fotos',
      pl: 'Wszystkie fotografie'
    },
    'about_this_presentation': {
      en: 'About this presentation',
      de: 'Über diese Präsentation',
      pl: 'O tej prezentacji'
    },
    'zur_ausstellung': {
      en: 'Open exhibition',
      de: 'Zur Ausstellung',
      pl: 'Na wystawę'
    },
    'join_in': {
      en: 'Join in!',
      de: 'Machen Sie mit!',
      pl: 'Dołącz!'
    },
    'impressum': {
      en: 'Legal Notice',
      de: 'Impressum',
      pl: 'Nota prawna'
    },
    'privacy_statement': {
      en: 'Privacy Statement',
      de: 'Datenschutzerklärung',
      pl: 'Oświadczenie o prywatności'
    },
    'ghkw_website': {
      en: 'GHKW Homepage',
      de: 'GHKW-Website',
      pl: 'Strona startowa GHWK'
    },
    'gefoeredert_von': {
      en: 'Sponsored by',
      de: 'Gefördert von',
      pl: 'Projekt sfinansowany przez'
    },
    'exponate_ausstellung_zum_thema': {
      en: 'Exhibit items with theme',
      de: 'Exponate in der Ausstellung zum Thema',
      pl: 'Eksponaty w prezentacji na temat'
    },
    'exponate_ausstellung_zur_person': {
      en: 'Exhibit items depicting',
      de: 'Exponate in der Ausstellung mit Person',
      pl: 'Eksponaty w prezentacji z osobą'
    },
    'exponate_ausstellung_mit_datum': {
      en: 'Exhibit items with date',
      de: 'Exponate in der Ausstellung mit Datum',
      pl: 'Eksponaty w prezentacji z datą'
    },
    'exponate_ausstellung_an_ort': {
      en: 'Exhibit items at this location',
      de: 'Exponate in der Ausstellung an diesem Ort',
      pl: 'Eksponaty w prezentacji w tym miejscu'
    },
    'exponate_bestand_zum_thema': {
      en: 'More photos with theme',
      de: 'Weitere Fotos zum Thema',
      pl: 'Więcej zdjęć'
    },
    'exponate_bestand_zur_person': {
      en: 'More photos showing',
      de: 'Weitere Fotos mit Person',
      pl: 'Więcej zdjęć'
    },
    'exponate_bestand_mit_datum': {
      en: 'More photos with date',
      de: 'Weitere Fotos mit Datum',
      pl: 'Więcej zdjęć'
    },
    'exponate_bestand_an_ort': {
      en: 'More photos at this location',
      de: 'Weitere Fotos an diesem Ort',
      pl: 'Więcej zdjęć'
    },
    'thema': {
      en: 'Theme',
      de: 'Thema',
      pl: 'Temat'
    },
    'back': {
      en: 'back',
      de: 'zurück',
      pl: 'z powrotem'
    },
    'Nachname': {
      en: 'Surname',
      de: 'Nachname',
      pl: 'Nazwisko'
    },
    'Vorname': {
      en: 'Given name',
      de: 'Vorname',
      pl: 'Imię'
    },
    'Geburtsdatum': {
      en: 'Date of birth',
      de: 'Geburtsdatum',
      pl: 'Data urodzenia'
    },
    'Todesdatum': {
      en: 'Date of death',
      de: 'Todesdatum',
      pl: 'Data śmierci'
    },
    'map': {
      en: 'Map',
      de: 'Karte',
      pl: 'Mapa'
    },
    'foto': {
      en: 'Photo',
      de: 'Foto',
      pl: 'Zdjęcie'
    },
    'nr': {
      en: 'No.',
      de: 'Nr.',
      pl: 'Nie.'
    },
    'follow_us_on': {
      en: 'Follow us on',
      de: 'Folgen Sie uns auf',
      pl: 'Obserwuj nas na'
    }

  },
  'wp': {
    'taxonomies': {
      'sprache': {
        en: 14,
        de: 13,
        pl: 12
      }
    }
  },
  'metadata_label': {
    'Title': {
      en: 'Title',
      de: 'Titel',
      pl: 'Tytuł'
    },
    'Inventarnummer': {
      en: 'Inventory Number',
      de: 'Inventarnummer',
      pl: 'Numer inwentarzowy'
    },
    'thematische Kategorie': {
      en: 'Themes',
      de: 'Themen',
      pl: 'Tematy'
    },
    'Format': {
      en: 'Format',
      de: 'Format',
      pl: 'Format'
    },
    'Material': {
      en: 'Material',
      de: 'Material',
      pl: 'Material'
    },
    'laufende Nummer': {
      en: 'Serial Number',
      de: 'Laufende Nummer',
      pl: 'Numer porządkowy'
    },
    'Bestellnummer': {
      en: 'Order Number',
      de: 'Bestellnummer',
      pl: 'Numer zamówienia'
    },
    'Kommentar Urheber': {
      en: 'Caption',
      de: 'Beschriftung',
      pl: 'Opis'
    },
    'Datum (Ausgabe)': {
      en: 'Date',
      de: 'Datum',
      pl: 'Data'
    },
    'Ort': {
      en: 'Location',
      de: 'Ort',
      pl: 'Miejsce'
    },
    'Orte': {
      en: 'Locations',
      de: 'Orte',
      pl: 'miejsc'
    },
    'Person Ausgabe': {
      en: 'Persons',
      de: 'Personen',
      pl: 'Osobay'
    },
    'Personen': {
      en: 'Persons',
      de: 'Personen',
      pl: 'Osobay'
    },
    'Signatur': {
      en: 'Signature',
      de: 'Signatur',
      pl: 'Podpis'
    },
    'Katalogtext': {
      en: 'Katalogtext',
      de: 'Katalogtext',
      pl: 'Katalogtext'
    },
    'Pappe': {
      en: 'Cardboard',
      de: 'Pappe',
      pl: 'Pappe'
    },
    'Transkription-Beschriftung': {
      en: 'Caption',
      de: 'Beschriftung',
      pl: 'Opis'
    }
  },
  'ghwk:subjectcategory': {
    'Gewalthandlungen': {
      en: 'Acts of Violence',
      de: 'Gewalthandlungen',
      pl: 'Akty przemocy'
    },
    'Fluchtbewegungen': {
      en: 'Refugee Movements',
      de: 'Fluchtbewegungen',
      pl: 'Ruchy uchodźcze '
    },
    'Kriegsgerät': {
      en: 'Armament',
      de: 'Kriegsgerät',
      pl: 'Maszyneria wojenna'
    },
    'Kriegsgefangene': {
      en: 'Prisoners of War',
      de: 'Kriegsgefangene',
      pl: 'Jeńcy wojenni'
    },
    'Polnische Bevölkerung': {
      en: 'Polish Population',
      de: 'Polnische Bevölkerung',
      pl: 'Ludność polska'
    },
    'Kriegszerstörungen': {
      en: 'Wartime Destruction',
      de: 'Kriegszerstörungen',
      pl: 'Zniszczenia wojenne'
    },
    'Jüdisch-polnische Bevölkerung': {
      en: 'Jewish-Polish Population',
      de: 'Jüdisch-polnische Bevölkerung',
      pl: 'Polska ludność żydowska; polscy Żydzi'
    },
    'Polnische Soldaten': {
      en: 'Polish Soldiers',
      de: 'Polnische Soldaten',
      pl: 'Żołnierze polscy'
    },
    'Wehrmachtssoldaten': {
      en: 'Wehrmacht Soldiers',
      de: 'Wehrmachtssoldaten',
      pl: 'Żołnierze Wehrmachtu'
    },
    'Soldatenalltag': {
      en: 'Soldiers\' Everyday Life',
      de: 'Soldatenalltag',
      pl: 'Życie codzienne żołnierzy'
    },
    'Volksdeutsche Bevölkerung': {
      en: '"Volksdeutsche" or "ethnic German" Population',
      de: 'Volksdeutsche Bevölkerung',
      pl: 'Ludność etnicznie niemiecka; volksdeutsche'
    }
  },
  'ghwk:material': {
    'Abzug auf Silbergelatinepapier, matt': {
      en: 'Silver Gelatin Print, matt',
      de: 'Abzug auf Silbergelatinepapier, matt',
      pl: 'Odbitka żelatynowo-srebrowa na papierze matowym'
    },
    'Abzug auf Silbergelatinepapier, glänzend': {
      en: 'Silver Gelatin Print, glossy',
      de: 'Abzug auf Silbergelatinepapier, glänzend',
      pl: 'Odbitka żelatynowo-srebrowa na papierze błyszczącym'
    },
    'Kontaktabzug auf Silbergelatinepapier, glänzend': {
      en: 'Contact print on silver gelatin paper, glossy',
      de: 'Kontaktabzug auf Silbergelatinepapier, glänzend',
      pl: 'Odbitka kontaktowa, żelatynowo-srebrowa na papierze błyszczącym'
    }
  }
}

export default state
