import axios from 'axios'

const state = {
  apiBaseUrl: 'https://wp.ghwk-db.de/wp-json/wp/v2/',
  homeAccordion: null,
  pages: null,
  errors: []
}

export default {
  namespaced: true,
  state,
  getters: {
    baseUrl (state) {
      return state.apiBaseUrl
    },
    homeAccordion (state) {
      return state.homeAccordion
    },
    pages (state) {
      return state.pages
    },
    errors (state) {
      return state.errors
    }
  },
  mutations: {
    setHomeAccordion (state, payload) {
      state.homeAccordion = payload
    },
    setPages (state, payload) {
      state.pages = payload
    },
    addError (state, payload) {
      state.errors.push(payload)
    }
  },
  actions: {
    fetchHomeAccordion (context) {
      axios.get(state.apiBaseUrl + 'accordion/', {
        params: {
          'per_page': '100',
          'filter[orderby]': 'menu_order',
          'order': 'asc',
          'ausstellungen': 10,
          'bereich': 11
        }
      })
      .then(response => {
        context.commit('setHomeAccordion', response.data)
      })
      .catch(e => {
        context.commit('addError', e)
      })
    },
    fetchPages (context) {
      axios.get(state.apiBaseUrl + 'pages/', {
        params: {
          'per_page': '100'
        }
      })
      .then(response => {
        context.commit('setPages', response.data)
      })
      .catch(e => {
        context.commit('addError', e)
      })
    }
  }
}