<template>
  <div id="app">
    <!-- <navbar/> -->
    <router-view/>
    <site-footer/>
    <template v-if="loaded"></template>
  </div>
</template>

<script>
  // import navbar from '@/components/navigation/Navbar'
  import footer from '@/components/Footer'
  import { mapActions } from 'vuex'
  export default {
    components: {
      // 'navbar': navbar,
      'site-footer': footer
    },
    methods: {
      ...mapActions([
        'items/fetchPhotos',
        'media/fetchMedia',
        'locations/fetchLocations',
        'persons/fetchPersons',
        'wordpress/fetchHomeAccordion',
        'wordpress/fetchPages'
      ])
    },
    created () {
      this['items/fetchPhotos']()
      this['media/fetchMedia']()
      this['locations/fetchLocations']()
      this['persons/fetchPersons']()
      this['wordpress/fetchHomeAccordion'](),
      this['wordpress/fetchPages']()
    },
    computed: {
      loaded() {
        if (
          this.$store.state.items.photos.length > 0 &&
          this.$store.state.media.media.length > 0 &&
          this.$store.state.locations.locations.length > 0 &&
          this.$store.state.persons.persons.length > 0 &&
          this.$store.state.wordpress.pages !== null &&
          this.$store.state.wordpress.homeAccordion !== null
        ) {
          document.dispatchEvent(new Event("x-app-rendered"))
          return true
        } else {
          return false
        }
      }
    }
  }
</script>

<style lang="scss">
@import '~@/styles/custom-bootstrap.scss';
@import '~@/styles/bootstrap.scss';
// @import '../node_modules/bootstrap/scss/bootstrap.scss';
@import '../node_modules/bootstrap-vue/dist/bootstrap-vue.min.css';
@import './styles/theme.scss';

@media (min-width: 768px) and (max-width: 900px) {
  .main_nav_bars {
      padding: 0.25em;
    }
}
.language_selector {
    position: relative;
    left: 0;
    top: 20px;
    @media (max-width: 767px) {
      position: relative;
      left: auto;
      top: auto;
    }
}
.caret-right {
  position: absolute;
  right: 16px;
  top: 6px;
  @media (max-width: 430px) {
    display: none;
  }
}
.acc_home_title_container {
  position: relative;
}
.acc_home_title {
  display: block;
  @media (max-width: 430px) {
    white-space: normal;
  }
}
.footer_sponsor_claim {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
}

.footer_links_extra_margin {
  margin-top: 61px;
}

.list_title {
  display: block;
  font-size: 1rem;
  font-weight: bold;
  padding: 1rem 0 1rem 0.375rem;
  border-top: 4px solid #d5d5d5;
  border-bottom: 4px solid #d5d5d5;
  background-color: #f5f5f5;
}
.exhibition_side_col {
  h1 {
    font-size: 1.75rem;
  }
  h2 {
    font-size: 1.5rem;
  }
  h3 {
    font-size: 1.25rem;
  }
}
</style>
