import axios from 'axios'

const state = {
  apiBaseUrl: 'https://ghwk-db.de/api/',
  apiPerPage: 999,
  persons: [],
  errors: []
}

const localeCompareSupportsLocales = function () {
  try {
    'foo'.localeCompare('bar', 'i');
  } catch (e) {
    return e.name === 'RangeError';
  }
  return false;
}

const comparePersonsProperty = function(a, b, supportLocales) {
  return ((a && a[0]) || (b && b[0])) ? 
    (!a || !a[0] ? -1 : !b || !b[0] ? 1 : 
      supportLocales? 
        a[0]['@value'].localeCompare(b[0]['@value'], 'de', {sensitivity: 'accent'}) 
        : a[0]['@value'].localeCompare(b[0]['@value'])
    ) : 0;
}

export default {
  namespaced: true,
  state,
  getters: {
    persons (state) {
      return state.persons
    },
    errors (state) {
      return state.errors
    }
  },
  mutations: {
    setPersons (state, payload) {
      let supportLocales = localeCompareSupportsLocales()
      payload.sort((a, b) => {
        if (a['foaf:familyName'] && b['foaf:familyName']) {
          return comparePersonsProperty(a['foaf:familyName'], b['foaf:familyName'], supportLocales)
        } else if (!a['foaf:familyName'] && b['foaf:familyName']) {
          return comparePersonsProperty(a['dcterms:title'], b['foaf:familyName'], supportLocales)
        } else if (a['foaf:familyName'] && !b['foaf:familyName']) {
          return comparePersonsProperty(a['foaf:familyName'], b['dcterms:title'], supportLocales)
        } else {
          return comparePersonsProperty(a['foaf:familyName'], b['foaf:familyName'], supportLocales) || 
          comparePersonsProperty(a['dcterms:title'], b['dcterms:title'], supportLocales)
        }
      })
      state.persons = payload
    },
    addError (state, payload) {
      state.errors.push(payload)
    }
  },
  actions: {
    fetchPersons (context) {
      let url = state.apiBaseUrl + 'items';
      axios.get(url, {
        params: {
          'per_page': state.apiPerPage,
          'resource_template_id': 3
          // 'resource_class_label': 'Person'
        }
      })
      .then(response => {
        context.commit('setPersons', response.data)
      })
      .catch(e => {
        context.commit('addError', 'fetchPersons - ' + e)
      })
    }
  }
}