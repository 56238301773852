import Vue from 'vue'
import BootstrapVue from 'bootstrap-vue'
import App from './App.vue'
import router from './router'
import store from './store/index'
import Viewer from 'v-viewer'

Vue.config.productionTip = false
Vue.use(BootstrapVue)
Vue.use(Viewer)

Viewer.setDefaults({
  url(image) {
    return image.src.replace('/files/large/', '/files/original/')
  }
})

new Vue({
  router,
  store,
  render: h => h(App)
  // ,
  // mounted: () => document.dispatchEvent(new Event("x-app-rendered")),
}).$mount('#app')
