<template>
  <div role="tablist">
    <b-card no-body class="mb-1"
      v-if="entry.sprache[0] === translate.wp.taxonomies.sprache[language] && entry.start !== '1'"
      v-for="(entry, index) in accordion"
      v-bind:key="'acc_home_' + index"
    >
      <b-card-header header-tag="header" class="p-1" role="tab">
        <b-btn block href="#" v-b-toggle="'acc_home_collapse_' + index" variant="info" class="text-left">
          <div class="acc_home_title_container">
            <span class="acc_home_title">{{entry.title.rendered}}</span>
            <i class="fa fa-caret-down caret-right"></i>
          </div>
        </b-btn>
      </b-card-header>
      <b-collapse v-if="index === 0" :id="'acc_home_collapse_' + index" visible accordion="acc_home" role="tabpanel">
        <b-card-body v-html="entry.content.rendered"></b-card-body>
      </b-collapse>
      <b-collapse v-else :id="'acc_home_collapse_' + index" accordion="acc_home" role="tabpanel">
        <b-card-body v-html="entry.content.rendered"></b-card-body>
      </b-collapse>
    </b-card>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
export default {
  name: 'HomeAccordion',
  computed: {
      ...mapGetters({
        language: 'language',
        translate: 'translate/translate',
        accordion: 'wordpress/homeAccordion'
      }),
  }
}
</script>