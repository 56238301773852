import axios from 'axios'

const state = {
  apiBaseUrl: 'https://ghwk-db.de/api/',
  apiPerPage: 999,
  locations: [],
  errors: []
}

const localeCompareSupportsLocales = function () {
  try {
    'foo'.localeCompare('bar', 'i');
  } catch (e) {
    return e.name === 'RangeError';
  }
  return false;
}

export default {
  namespaced: true,
  state,
  getters: {
    locations (state) {
      return state.locations
    },
    errors (state) {
      return state.errors
    }
  },
  mutations: {
    setLocations (state, payload) {
      let supportLocales = localeCompareSupportsLocales()
      payload.sort((a,b) => {
        if (
          a['dcterms:title'] && a['dcterms:title'][0] && a['dcterms:title'][0]['@value'] &&
          b['dcterms:title'] && b['dcterms:title'][0] && b['dcterms:title'][0]['@value']
        ) {
          if (supportLocales) {
            return a['dcterms:title'][0]['@value'].localeCompare(b['dcterms:title'][0]['@value'], 'pl', {sensitivity: 'accent'});
          } else {
            return a['dcterms:title'][0]['@value'].localeCompare(b['dcterms:title'][0]['@value']);
          }
        }
        return 0
      })
      state.locations = payload
    },
    addError (state, payload) {
      state.errors.push(payload)
    }
  },
  actions: {
    fetchLocations (context) {
      let url = state.apiBaseUrl + 'items';
      axios.get(url, {
        params: {
          'per_page': state.apiPerPage,
          'resource_template_id': 4
          // 'resource_class_label': 'Location'
        }
      })
      .then(response => {
        context.commit('setLocations', response.data)
      })
      .catch(e => {
        context.commit('addError', e)
      })
    }
  }
}