<template>
  <div class="container-fluid header bg-white">
    <div class="row">
      <div class="col-lg-1 col-md-1">
        <b-dropdown id="main-navigation" role="navigation" variant="link p-0" no-caret>
          <template slot="button-content">
            <i class="fa fa-bars main_nav_bars"></i>
            <span class="sr-only">navigation button</span>
          </template>
          <b-dropdown-item :to="{ name: 'home'}" v-html="translate.global.home[language]"></b-dropdown-item>
          <b-dropdown-item :to="{ name: 'item'}" v-html="translate.global.zur_ausstellung[language]"></b-dropdown-item>
          <b-dropdown-item :to="{ name: 'map'}" v-html="translate.global.map[language]"></b-dropdown-item>
          <b-dropdown-item :to="{ name: 'exhibition'}" v-html="translate.global.all_photos[language]"></b-dropdown-item>
          <b-dropdown-item :to="{ name: 'about'}" v-html="translate.global.about_this_presentation[language]"></b-dropdown-item>
        </b-dropdown>
      </div>
      <div class="col-lg-8 col-md-8 pt-3">
        <p class="lead" v-html="title"></p>
      </div>
      <div class="col-lg-3 col-md-3">
        <b-dropdown variant="link p-0" class="language_selector">
          <template slot="button-content">
            <span>
              {{translate.global.select_language[language]}}
              {{languageUpper}}
            </span>
          </template>
          <b-dropdown-item v-on:click="changeLanguage('de')">DE</b-dropdown-item>
          <b-dropdown-item v-on:click="changeLanguage('en')">EN</b-dropdown-item>
          <b-dropdown-item v-on:click="changeLanguage('pl')">PL</b-dropdown-item>
        </b-dropdown>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters, mapActions } from 'vuex'
export default {
  name: 'Header',
  props: {
    title: String
  },
  methods: {
    ...mapActions(['changeLanguage'])
  },
  computed: {
    ...mapGetters({
      language: 'language',
      languageUpper: 'languageUpper',
      translate: 'translate/translate'
    })
  }
}
</script>