<template>
  <div id="home">
    <site-header :title="translate.global.exhibit_title[language] + '<br />' + translate.global.exhibit_subtitle[language]"/>
    <b-container fluid class="pb-3 bg-white">
      <div class="row">
        <div class="col-lg-8">
          <div class="imagecontainer">
            <img class="img-fluid mb-4" src="@/assets/img/banner_skory.jpg"
              alt="Der deutsche Überfall auf Polen 1939" style="width:100%;">
            <div class="top-left">
              <h3 v-html="translate['global']['Die Seeliger-Fotos'][language]"></h3>
            </div>
          </div>
          <div class="home_body_text"
            v-if="entry.sprache[0] === translate.wp.taxonomies.sprache[language] && entry.start === '1'"
            v-for="(entry, index) in accordion"
            v-bind:key="'acc_home_' + index"
            v-html="entry.content.rendered"
          ></div>
          <HomeAccordion/>
        </div>
        <div class="col-lg-4 text-center side-content" id="aside-home">
          <DefaultAside />
        </div>
      </div>
    </b-container>
  </div>
</template>

<script>
import header from '@/components/Header.vue'
import HomeAccordion from '@/components/partials/HomeAccordion.vue'
import DefaultAside from '@/components/partials/DefaultAside.vue'
import { mapGetters } from 'vuex'

export default {
  name: 'home',
  components: {
    HomeAccordion,
    DefaultAside,
    'site-header': header
  },
  computed: {
    ...mapGetters({
      language: 'language',
      translate: 'translate/translate',
      accordion: 'wordpress/homeAccordion'
    })
  }
}
</script>
<style lang="scss" scoped>
.imagecontainer {
    position: relative;
    text-align: center;
    // color: white;
  .top-left {
    position: absolute;
    top: 16px;
    left: 16px;
    h3 {
      color: #000;
      padding: .6rem .8rem;
      background: rgba(255,255,255,0.8);
    }
  }
}
</style>