import axios from 'axios'

const state = {
  apiBaseUrl: 'https://ghwk-db.de/api/',
  apiPerPage: 999,
  photos: [],
  photo: null,
  photosInExhibition: [],
  currentPhotoId: null,
  nextPhotoId: null,
  previousPhotoId: null,
  map: null,
  errors: []
}

export default {
  namespaced: true,
  state,
  getters: {
    baseUrl (state) {
      return state.apiBaseUrl
    },
    photos (state) {
      return state.photos
    },
    photo (state) {
      return state.photo
    },
    photosInExhibition (state) {
      return state.photosInExhibition
    },
    currentPhotoId (state) {
      return state.currentPhotoId
    },
    nextPhotoId (state) {
      return state.nextPhotoId
    },
    previousPhotoId (state) {
      return state.previousPhotoId
    },
    map (state) {
      return state.map
    },
    errors (state) {
      return state.errors
    }
  },
  mutations: {
    setPhotos (state, payload) {
      let photosInExhibition = []
      let ids = []
      photosInExhibition = payload.filter(
        photo => photo['ghwk:exhibitstatus'][0]['@value'] === 'in Ausstellung' &&
        photo['o:media'].length > 0
      )
      photosInExhibition.sort((a, b) => {
        if ('ghwk:exhibitnumber' in a && 'ghwk:exhibitnumber' in b) {
          if (parseInt(a['ghwk:exhibitnumber'][0]['@value']) < parseInt(b['ghwk:exhibitnumber'][0]['@value'])) {
            return -1;
          }
          if (parseInt(a['ghwk:exhibitnumber'][0]['@value']) > parseInt(b['ghwk:exhibitnumber'][0]['@value'])) {
            return 1;
          }
        }
        return 0;
      })
      for (let index = 0; index < photosInExhibition.length; index++) {
        ids.push(photosInExhibition[index]['o:id'])
      }
      state.photos = payload.sort((a, b) => {
        if ('ghwk:currentnumber' in a && 'ghwk:currentnumber' in b) {
          if (parseInt(a['ghwk:currentnumber'][0]['@value']) < parseInt(b['ghwk:currentnumber'][0]['@value'])) {
            return -1;
          }
          if (parseInt(a['ghwk:currentnumber'][0]['@value']) > parseInt(b['ghwk:currentnumber'][0]['@value'])) {
            return 1;
          }
        }
        return 0;
      })
      state.photosInExhibition = ids
    },
    setPhoto (state, payload) {
      if (!payload) {
        state.photo = null
      } else {
        state.photo = payload
      }
    },
    setPhotosInExhibition (state, payload) {
      if (!payload) {
        state.photosInExhibition = null
      } else {
        state.photosInExhibition = payload
      }
    },
    setCurrentPhotoId (state, payload) {
      if (!payload) {
        state.currentPhotoId = null
      } else {
        state.currentPhotoId = payload
      }
    },
    setNextPhotoId (state, payload) {
      if (!payload) {
        state.nextPhotoId = null
      } else {
        let nextId = state.photosInExhibition[0]? state.photosInExhibition[0] : null
        let currentIDIndex = state.photosInExhibition.findIndex(photoInEx => photoInEx === Number(payload))
        if (currentIDIndex !== -1 && state.photosInExhibition[(currentIDIndex + 1)]) {
          nextId = state.photosInExhibition[(currentIDIndex + 1)]
        } else if (currentIDIndex === -1) {
          nextId = null
        }
        state.nextPhotoId = nextId
      }
    },
    setPreviousPhotoId (state, payload) {
      if (!payload) {
        state.previousPhotoId = null
      } else {
        let prevId = state.photosInExhibition.slice(-1)[0]
        let currentIDIndex = state.photosInExhibition.findIndex(photoInEx => photoInEx === Number(payload))
        if (currentIDIndex !== -1 && state.photosInExhibition[(currentIDIndex - 1)]) {
          prevId = state.photosInExhibition[(currentIDIndex - 1)]
        } else if (currentIDIndex === -1) {
          prevId = null
        }
        state.previousPhotoId = prevId
      }
    },
    setMap (state, payload) {
      if (!payload) {
        state.map = null
      } else {
        state.map = payload
      }
    },
    addError (state, payload) {
      state.errors.push(payload)
    }
  },
  actions: {
    // async fetchPhotos (context) {
    //   let url = state.apiBaseUrl + 'items';
    //     let json = await axios.get(url, {
    //       params: {
    //         'per_page': state.apiPerPage,
    //         'resource_template_id': 2
    //       }
    //     })
    //     .catch(e => {
    //       context.commit('addError', e)
    //     })
    //     context.commit('setPhotos', json.data)
    // },
    fetchPhotos ({ commit }) {
      let url = state.apiBaseUrl + 'items';
      // setTimeout(() => {
        axios.get(url, {
          params: {
            'per_page': state.apiPerPage,
            'resource_template_id': 2,
            // 'resource_class_label': 'Physical Object'
            'property[0][property]': 186,
            'property[0][type]': 'eq',
            'property[0][text]': 'in Ausstellung',
            'property[1][joiner]': 'or',
            'property[1][property]': 186,
            'property[1][type]': 'eq',
            'property[1][text]': 'im Bestand'
          }
        })
        .then(response => {
          commit('setPhotos', response.data)
        })
        .catch(e => {
          commit('addError', e)
        })
      // }, 5000)
    },
    fetchPhotoFromStore (context, id) {
      let currentPhoto = false
      if (id) {
        currentPhoto = context.state.photos.find(x => x['o:id'] === Number(id))
      }
      if (currentPhoto) {
        context.commit('setPhoto', currentPhoto)
      } else if (context.state.photosInExhibition.length > 0) {
        id = context.state.photosInExhibition[0]
        context.commit('setPhoto', context.state.photos.find(photo => photo['o:id'] === id))
      }
      context.commit('setCurrentPhotoId', id)
      context.commit('setNextPhotoId', id)
      context.commit('setPreviousPhotoId', id)
    },
    fetchPhoto (context, id) {
      if (context.state.photos.length > 0) {
        context.dispatch('fetchPhotoFromStore', id)
      } else {
        let promise = new Promise((resolve, reject) => {
          let check = () => {
            if (context.state.photos.length > 0) {
              resolve()
            } else if (context.state.photos.errors > 0) {
              reject('Error waiting for photo')
            } else {
              setTimeout(check, 100);
            }
          }
          check()
        })
        promise.then(() => {
          context.dispatch('fetchPhotoFromStore', id)
        })
        .catch((err) => {
          context.commit('addError', err)
        })
      }
    },
    fetchMapFromStore (context) {
      var map = []
      if (context.state.map === null) {
        for (let index = 0; index < context.state.photos.length; index++) {
          if (
            typeof context.state.photos[index]['ghwk:locationdisplay'] !== 'undefined' &&
            context.state.photos[index]['ghwk:locationdisplay'].length > 0 &&
            typeof context.state.photos[index]['ghwk:latitude'] !== 'undefined' &&
            context.state.photos[index]['ghwk:latitude'].length > 0 &&
            typeof context.state.photos[index]['ghwk:longitude'] !== 'undefined' &&
            context.state.photos[index]['ghwk:longitude'].length > 0
          ) {
            let inMapIndex = map.findIndex(item =>
              item.latitude === context.state.photos[index]['ghwk:latitude'][0]['@value'] &&
              item.longitude === context.state.photos[index]['ghwk:longitude'][0]['@value']
            )
            let media
            if (context.state.photos[index]['o:media'] && context.state.photos[index]['o:media'][2]) {
                media = context.rootState.media.media.find(mediaitem =>
                  mediaitem['o:id'] === context.state.photos[index]['o:media'][2]['o:id'])
                if (media) {media = media['o:thumbnail_urls']['square']}
            }

            if (inMapIndex === -1) {
              map.push({
                latitude: context.state.photos[index]['ghwk:latitude'][0]['@value'],
                longitude: context.state.photos[index]['ghwk:longitude'][0]['@value'],
                locationdisplay: context.state.photos[index]['ghwk:locationdisplay'],
                photos: [{
                  lfdnr: context.state.photos[index]['ghwk:currentnumber'][0]['@value'],
                  id: context.state.photos[index]['o:id'],
                  media: media
                }]
              })
            } else {
              map[inMapIndex].photos.push({
                lfdnr: context.state.photos[index]['ghwk:currentnumber'][0]['@value'],
                id: context.state.photos[index]['o:id'],
                media: media
              })
            }
          }
        }
        context.commit('setMap', map)
      }
    },
    fetchMap (context) {
      if (context.state.map === null) {
        if (context.state.photos.length > 0  &&
          context.rootState.media.media.length > 0)
        {
          context.dispatch('fetchMapFromStore')
        } else {
          let promise = new Promise((resolve, reject) => {
            let check = () => {
              if (context.state.photos.length > 0  &&
                context.rootState.media.media.length > 0) {
                resolve()
              } else if (context.state.photos.errors > 0) {
                reject('Error waiting for photo and media')
              } else {
                setTimeout(check, 100);
              }
            }
            check()
          })
          promise.then(() => {
            context.dispatch('fetchMapFromStore')
          })
          .catch((err) => {
            context.commit('addError', err)
          })
        }
      }
    }
  }
}