<template>
  <footer>
    <div class="container-fluid bg-white pb-3 footer">
      <div class="row">
        <div class="col-md-8 text-center" v-if="$route.name !== 'home'">
          <img class="img-fluid mb-4 mt-2" src="@/assets/img/csm_logo-de_480px_mt.png" width="380" alt="Haus am Wannsee">
          <p class="text-center">
            <router-link
              :to="{ name: 'home'}"
              v-html="translate.global.home[language]"></router-link> |
            <router-link
              :to="{ name: 'exhibition'}"
              v-html="translate.global.all_photos[language]"></router-link> |
            <router-link
              :to="{ name: 'map'}"
              v-html="translate.global.map[language]"></router-link> |
            <router-link
              :to="{ name: 'about'}"
              v-html="translate.global.about_this_presentation[language]"></router-link> |
            <a class="mylinks" href="https://www.ghwk.de/kontakt/"
              target="_blank" rel="noopener"
              v-html="translate.global.kontakt[language]"></a> |
            <a class="mylinks" href="https://www.ghwk.de/impressum/"
              target="_blank" rel="noopener"
              v-html="translate.global.impressum[language]"></a> |
            <a class="mylinks" href="https://www.ghwk.de/datenschutzerklaerung/"
              target="_blank" rel="noopener"
              v-html="translate.global.privacy_statement[language]"></a> |
            <a class="mylinks" href="https://www.ghwk.de/"
              target="_blank" rel="noopener"
              v-html="translate.global.ghkw_website[language]"></a>
          </p>
        </div>
        <div class="col-md-8 text-center" v-else>
          <p class="text-center footer_links_extra_margin">
            <router-link
              :to="{ name: 'map'}"
              v-html="translate.global.map[language]"></router-link> |
            <a class="mylinks" href="https://www.ghwk.de/kontakt/"
              target="_blank" rel="noopener"
              v-html="translate.global.kontakt[language]"></a> |
            <a class="mylinks" href="https://www.ghwk.de/impressum/"
              target="_blank" rel="noopener"
              v-html="translate.global.impressum[language]"></a> |
            <a class="mylinks" href="https://www.ghwk.de/datenschutzerklaerung/"
              target="_blank" rel="noopener"
              v-html="translate.global.privacy_statement[language]"></a> |
            <a class="mylinks" href="https://www.ghwk.de/"
              target="_blank" rel="noopener"
              v-html="translate.global.ghkw_website[language]"></a>
          </p>
        </div>
        <div class="col-md-4 text-center">
           <p class="small text-center pt-2" v-html="translate.global.gefoeredert_von[language]"></p>
          <div class="row">
            <div class="col">
              <a href="https://www.digis-berlin.de/" target="_blank" rel="noopener">
                <img class="img-fluid mx-auto d-block mt-4" style="max-width:100px;" src="@/assets/img/digiS_logo_200px.png" alt="Logo">
              </a>
            </div>
            <div class="col">
              <a href="https://www.berlin.de/sen/kulteu/" target="_blank" rel="noopener">
                <img class="img-fluid mx-auto d-block mt-4" style="max-width:120px;" src="@/assets/img/SenKE_rot_hoch_200px.jpg" alt="Logo">
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </footer>
</template>
<script>
import { mapGetters } from 'vuex'
export default {
  name: 'Footer',
  computed: {
    ...mapGetters({
      language: 'language',
      translate: 'translate/translate',
    })
  }
}
</script>