// This is a read only store
import state from './translate_state'

export default {
  namespaced: true,
  state,
  getters: {
    translate (state) {
      return state
    }
  }
}